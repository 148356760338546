<template>
  <v-container
    id="our-clients"
    fluid
    class="py-12 d-flex justify-center align-center flex-column white"
  >
    <v-row class="py-12">
      <v-col cols="12">
        <h1 class="primary--text text--darken-1 text-center">
          Nuestros clientes
        </h1>
      </v-col>
    </v-row>
    <v-row class="pb-12">
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('http://tepic.gob.mx')"
          >
            <v-img src="img/clients/tepic.png" alt="Gobierno de Tepic"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://siapa.tepic.gob.mx')"
          >
            <v-img src="img/clients/siapa.png" alt="Siapa"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.grupoalica.com.mx/')"
          >
            <v-img src="img/clients/alica.png" alt="Alica"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.uva.edu.mx/')"
          >
            <v-img src="img/clients/client-1.jpg" alt="Cliente"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://colegio.uva.edu.mx/Tepic/preescolar')"
          >
            <v-img src="img/clients/ciprees.png" alt="Cliente"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://colegio.uva.edu.mx/Tepic/primaria')"
          >
            <v-img
              src="img/clients/primaria_vizcaya.webp"
              alt="Cliente"
            ></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLinl('https://colegio.uva.edu.mx/Tepic/secundaria')"
          >
            <v-img
              src="img/clients/secundaria_vizcaya.webp"
              alt="Cliente"
            ></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://colegio.uva.edu.mx/Tepic/preparatoria')"
          >
            <v-img
              src="img/clients/preparatoria_vizcaya.webp"
              alt="Cliente"
            ></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
          >
            <v-img src="img/clients/rndm.png" alt="rndm"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.zapateriaspaquita.com/')"
          >
            <v-img src="img/clients/paquita.jfif" alt="Paquita"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.simonbolivar.edu.mx/')"
          >
            <v-img src="img/clients/simon.png" alt="simon"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.coparmexnayarit.com/')"
          >
            <v-img src="img/clients/coparmex.png" alt="Coparmex"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.pandelacasa.com/')"
          >
            <v-img src="img/clients/casa.jfif" alt="Casa"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.foton.mx/')"
          >
            <v-img src="img/clients/foton2.png" alt="Carpintero"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>

      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.kioti.com.mx/')"
          >
            <v-img src="img/clients/kioti.jpg" alt="Kioti"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>

      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://www.facebook.com/llaossa/')"
          >
            <v-img src="img/clients/client-4.jpg" alt="Cliente"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://empresasiansa.cl/azucar-iansa/')"
          >
            <v-img src="img/clients/client-5.jpg" alt="Cliente"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
      <v-col
        cols="4"
        sm="4"
        md="4"
        lg="2"
        xl="2"
        class="d-flex justify-center align-center my-3"
      >
        <v-lazy>
          <v-avatar
            class="rounded-circle"
            :height="getHeightAndWidth"
            :width="getHeightAndWidth"
            @click="openLink('https://xhkg.tv/')"
          >
            <v-img src="img/clients/client-11.png" alt="Cliente"></v-img>
          </v-avatar>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-avatar {
  border: 2px solid #e3e3e3;
  transition: 200ms;
}
.v-avatar:hover {
  cursor: pointer;
  border-color: #192a67;
  transition: 200ms;
}
</style>
<script>
export default {
  computed: {
    getHeightAndWidth() {
      if (this.$vuetify.breakpoint.xs)
        return this.$vuetify.breakpoint.width / 3 - 40;
      if (this.$vuetify.breakpoint.sm)
        return this.$vuetify.breakpoint.width / 3 - 60;
      if (this.$vuetify.breakpoint.md)
        return this.$vuetify.breakpoint.width / 3 - 60;
      if (this.$vuetify.breakpoint.lg)
        return this.$vuetify.breakpoint.width / 7 - 80;

      return this.$vuetify.breakpoint.width / 11 - 80;
    },
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>
